<template>
  <div class="PN">
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item index="1"
        ><router-link to="Comment">我的评论</router-link></el-menu-item
      >
      <el-menu-item index="2"
        ><router-link to="Article">我的发布</router-link></el-menu-item
      >
      <el-menu-item index="3"
        ><router-link to="Collection">我的关注</router-link></el-menu-item
      >
      <el-menu-item index="4"
        ><router-link to="subscribe">我的订阅</router-link></el-menu-item
      >
    </el-menu>
    <div class="news">
      <router-view />
    </div>
  </div>
</template>
<script>
import {DELETE,QUERY,INSERT, UPDATE, QUERYED}  from '@/services/dao.js';

export default {
  data() {
    return {
      activeIndex: "1",
      activeIndex2: "1",
    };
  },
  // 由于该页面为个人中心的默认页面，为方便个人中心其他页面数据进更新后，及时在Personal Center/index.vue 回显
  created(){
    this.initInfo();
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    // 为Personal Center/index.vue 回显
    async initInfo(){
      let info =  await  QUERYED("post","",'User(where: {id: {_eq: '+this.$store.state.userinfo.id+'}}) { img integral Money  }');
      console.log("默认信息： ",info);
      this.$root.Hub.$emit('oneEvent3',info.data.User[0].img,info.data.User[0].integral,info.data.User[0].Money);
    },
  },
};
</script>
<style scoped>
.PN {
  background-color: #ffffff;
}
.news {
  width: 700px;
  /* border: 1px solid red; */
  display: inline-block;
  /* height: 924px; */
}
.el-menu--horizontal > .el-menu-item {
  margin-left: 100px;
  float: left;
  height: 60px;
  line-height: 60px;
  /* margin: 0; */
  border-bottom: 2px solid transparent;
  color: #909399;
}
.router-link-active {
  color: red !important;
}
</style>
